@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Inter", sans-serif, "Apple Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--primary-color);
  color: var(--tertiary-color);

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
input,
textarea,
button {
  font-family: inherit;
  color: var(--tertiary-color);
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Font Sizing */
h1 {
  font-size: 28px;
  font-weight: 600;
}

p {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

textarea,
input,
button {
  font-size: 18px;
  font-weight: 300;
}

@media (min-width: 480px) {
  h1 {
    font-size: 40px;
  }

  p {
    font-size: 22px;
    line-height: 36px;
  }

  textarea,
  input,
  button {
    font-size: 20px;
  }
}
